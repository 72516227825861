import OtherServicesWidget from "../components/OtherServicesWidget/OtherServicesWidget";
import ServiceText from "../components/ServiceText/ServiceText";
import styles from "./styles/Temizlik.module.css";
import kentTemizligiVideo from "../videos/kenttemizligi.mp4"
import { useTranslation } from 'react-i18next';

export default function Temizlik () {
    const { t } = useTranslation();
    return (
      <main>
        <div className={styles.mainLandingContainer}>
          <div className={styles.landingContainer}>
            <div className={styles.landingCoverContainer}>
              <h1 className={styles.landingHeader}>{t('services.cleaningTitle')}</h1>
            </div>
          </div>
        </div>
        <ServiceText 
            serviceHeader={t('services.cleaningTitle')} 
            serviceText={t('services.cleaningText')}
        />
        <div className={styles.serviceVideoContainer}>
          <video className={styles.serviceVideo} controls autoPlay>
            <source src={kentTemizligiVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <OtherServicesWidget />
      </main>
    );
  }