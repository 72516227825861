import OtherServicesWidget from "../components/OtherServicesWidget/OtherServicesWidget";
import ServiceText from "../components/ServiceText/ServiceText";
import styles from "./styles/Peyzaj.module.css";
import { useTranslation } from 'react-i18next';

export default function Peyzaj () {
    const { t } = useTranslation();
    return (
      <main>
        <div className={styles.mainLandingContainer}>
          <div className={styles.landingContainer}>
            <div className={styles.landingCoverContainer}>
              <h1 className={styles.landingHeader}>{t('services.landscapeTitle')}</h1>
            </div>
          </div>
        </div>
        <ServiceText 
            serviceHeader={t('services.landscapeTitle')} 
            serviceText={t('services.landscapeText')}
        />
        <OtherServicesWidget />
      </main>
    );
  }