import styles from "./styles/Contact.module.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import phone from "../images/phone.png";
import location from "../images/location.png";
import mail from "../images/email.png";
import { useTranslation } from 'react-i18next';

export default function Contact () {
  const { t } = useTranslation();
  return (
    <main>
      <div className={styles.mainLandingContainer}>
        <div className={styles.landingContainer}>
          <div className={styles.landingCoverContainer}>
            <h1 className={styles.landingHeader}>{t('contact.title')}</h1>
          </div>
        </div>
      </div>
      <div className={styles.contactInfoContainer}>
        <Container>
          <Row className={styles.contactRow}>
            <Col className={styles.contactContainer}> 
              <div className={styles.imageContainer}>
                <img src={location} alt="yer bilgisi" className={styles.contactIcon} />
              </div>
              <div className={styles.detailsContainer}>
                <p className={styles.contactDetails}>Yukarı Dikmen Mh. Turan Güneş Blv. 106/100 Çankaya/ANKARA</p>
              </div>
            </Col>
            <Col className={styles.contactContainer}>
              <div className={styles.imageContainer}>
                <img src={phone} alt="telefon bilgisi" className={styles.contactIcon} />
              </div>
              <div className={styles.detailsContainer}>
                <p className={styles.contactDetails}>0312 286 88 81</p>
              </div>
            </Col>
            <Col className={styles.contactContainer}>
              <div className={styles.imageContainer}>
                <img src={mail} alt="mail bilgisi" className={styles.contactIcon} />
              </div>
              <div className={styles.detailsContainer}>
                <p className={styles.contactDetails}>info@dortyildizas.com.tr</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mapContainer}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3062.508650250111!2d32.851725515379115!3d39.86284297943332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x395cd793699b6df!2zMznCsDUxJzQ2LjIiTiAzMsKwNTEnMTQuMSJF!5e0!3m2!1str!2str!4v1661447075735!5m2!1str!2str" title="Ofis yeri" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </main>
  );
}