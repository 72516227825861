import React from 'react';
import styles from "./style/OtherServicesWidget.module.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import katiatikplaceholder from "../../images/katiatikplaceholder.png";
import lojistikplaceholder from "../../images/lojistikplaceholder.jpg";
import depolamaplaceholder from "../../images/depolamaplaceholder.jpg";
import peyzajplaceholder from "../../images/peyzajplaceholder.jpg";
import kenttemizligi from "../../images/kenttemizligi.png";
import yenilenebilir from "../../images/yenilenebilir.jpg";
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import { useTranslation } from 'react-i18next';

function OtherServicesWidget() {
    const { t } = useTranslation();
    const cards = [
        {
            altText: "lojistik",
            fullName: t('services.transportTitle'),
            imgSrc: lojistikplaceholder,
            url: "../services/lojistik"
        },
        {
            altText: "depolama hizmetleri",
            fullName: t('services.storageTitle'),
            imgSrc: depolamaplaceholder,
            url: "../services/depolama"
        },
        {
            altText: "peyzaj",
            fullName: t('services.landscapeTitle'),
            imgSrc: peyzajplaceholder,
            url: "../services/peyzaj"
        },
        {
            altText: "atık",
            fullName: t('services.transferTitle'),
            imgSrc: katiatikplaceholder,
            url: "../services/atik"
        },
        {
            altText: "kent temizliği",
            fullName: t('services.cleaningTitle'),
            imgSrc: kenttemizligi,
            url: "../services/temizlik"
        },
        {
            altText: "yenilenebilir enerji",
            fullName: t('services.renewableTitle'),
            imgSrc: yenilenebilir,
            url: "../services/yenilenebilir"
        }
    ];

    const handleClick = () => {
        window.scrollTo(0, 0);
    } 

    return (
        <div className={styles.servicesContainer}>
            <h1 className={styles.servicesHeader}>{t('services.otherWidgetTxt')}</h1>
            <Container className={styles.serviceContainer}>
                <Row className={styles.serviceRow}>
                    {cards.map((card, index) => 
                        <Link key={index} to={card.url} className={styles.cardLink}>
                            <Card cardId={index} cardImg={card.imgSrc} cardAlt={card.altText} cardName={card.fullName} handleClick={handleClick} />
                        </Link>
                    )}
                </Row>
            </Container>
        </div>
    );
}

export default OtherServicesWidget;