import styles from "./styles/Services.module.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import katiatikplaceholder from "../images/katiatikplaceholder.png";
import lojistikplaceholder from "../images/lojistikplaceholder.jpg";
import depolamaplaceholder from "../images/depolamaplaceholder.jpg";
import peyzajplaceholder from "../images/peyzajplaceholder.jpg";
import kenttemizligi from "../images/kenttemizligi.png";
import turizm from "../images/turizm.jpg";
import yenilenebilir from "../images/yenilenebilir.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Services  () {
  const { t } = useTranslation();

  const handleClick = () => {
    window.scrollTo(0, 0);
  }  

  return (
    <main>
      <div className={styles.mainLandingContainer}>
        <div className={styles.landingContainer}>
          <div className={styles.landingCoverContainer}>
            <h1 className={styles.landingHeader}>{t('services.pageTitle')}</h1>
          </div>
        </div>
      </div>
      <Container className={styles.servicesContainer}>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={kenttemizligi} alt="kent temizliği" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.cleaningTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.cleaningText')}
            </p>
            <Link to="temizlik" variant="primary" className={styles.serviceDescriptionBtn} onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={katiatikplaceholder} alt="atık transferi" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.transferTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.transferText')}
            </p>
            <Link to="atik" variant="primary" className={styles.serviceDescriptionBtn}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={lojistikplaceholder} alt="taşımacılık" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.transportTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.transportText')}
            </p>
            <Link to="lojistik" variant="primary" className={styles.serviceDescriptionBtn} onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={yenilenebilir} alt="yenilenebilir" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.renewableTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.renewableText')}
            </p>
            <Link to="yenilenebilir" variant="primary" className={styles.serviceDescriptionBtn} onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={turizm} alt="turizm" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.tourismTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.tourismText')}
            </p>
            <Link to="turizm" variant="primary" className={styles.serviceDescriptionBtn}>
             <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={peyzajplaceholder} alt="peyzaj" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.landscapeTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.landscapeText')}
            </p>
            <Link to="peyzaj" variant="primary" className={styles.serviceDescriptionBtn} onClick={handleClick}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.serviceContainer}>
          <Col className={styles.serviceImageContainer}>
            <img src={depolamaplaceholder} alt="depolama" className={styles.serviceImage} />
          </Col>
          <Col className={styles.serviceDescriptionContainer}>
            <h1 className={styles.serviceDescriptionHeader}>{t('services.storageTitle')}</h1>
            <p className={styles.serviceDescriptionText}>
              {t('services.storageText')}
            </p>
            <Link to="depolama" variant="primary" className={styles.serviceDescriptionBtn}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </Col>
        </Row>
      </Container>
    </main>
  );
}