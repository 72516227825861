
import React from 'react';
import styles from "./style/ServiceText.module.css";

function ServiceText({serviceHeader, serviceText}) {
    return (
        <div className={styles.serviceTextContainer}>
            <h1 className={styles.serviceHeader}>{serviceHeader}</h1>
            <p className={styles.serviceText}>{serviceText}</p>
        </div>
    );
}

export default ServiceText;