import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from "../components/Card/Card";
import styles from "./styles/Vehicles.module.css";
import Carousel from "react-bootstrap/esm/Carousel";
import { useTranslation } from 'react-i18next';
import useVehicle from "../hooks/useVehicle";

export default function Vehicles () {
  const { t } = useTranslation();
  const VehicleData = useVehicle();
  const [index, setIndex] = useState(0);
  const [type, setType] = useState("temizlik");
  const [filteredCars, setFilteredCars] = useState(VehicleData.filter((vehicle) => vehicle.type === type));
  const [carouselImg, setCarouselImg] = useState([]);
  const carouselContainer = useRef();
  const carouselEl = useRef();

  useEffect(() => {
    setFilteredCars(VehicleData.filter((vehicle) => vehicle.type === type));
  }, [type, VehicleData]);

  useEffect(() => {
    carouselContainer.current = document.getElementById("carousel-veh");
    carouselEl.current = document.getElementById("carousel-element");
  }, []);

  const handleClick = (e) => {
    const vehicleId = e.target.getAttribute("data-card");
    setCarouselImg(filteredCars[vehicleId].cardCarousel);
    carouselContainer.current.setAttribute("class", `${styles.activeCarouselContainer}`);
  }

  const handleCloseCarousel = (e) => {
    if(e.target.id === "carousel-veh") {
      carouselContainer.current.setAttribute("class", `${styles.carouselContainer}`);
      setIndex(0);
    }
  }

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <main>
      <div id="carousel-veh" className={styles.carouselContainer} onClick={handleCloseCarousel}>
        <Carousel 
          variant="light" 
          className={styles.vehicleCarousel}
          activeIndex={index} 
          onSelect={handleSelect}
          fade
        >
          {carouselImg.map((image) =>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={image}
                alt="Carousel item"
              />
            </Carousel.Item>
          )}
        </Carousel>
      </div>
      <div className={styles.mainLandingContainer}>
        <div className={styles.landingContainer}>
          <div className={styles.landingCoverContainer}>
            <h1 className={styles.landingHeader}>{t('vehicles.title')}</h1>
          </div>
        </div>
      </div>
      <div className={styles.typeSelectorContainer}>
        <h4 
          className={type === "temizlik" ? styles.typeSelectorActive : styles.typeSelector}
          onClick={() => type !== "temizlik" && setType("temizlik")}
        >
          {t('vehicles.temizlikTxt')}
        </h4>
        <h4 
          className={type === "transfer" ? styles.typeSelectorActive : styles.typeSelector}
          onClick={() => type !== "transfer" && setType("transfer")}
        >
          {t('vehicles.transferTxt')}
        </h4>
        <h4 
          className={type === "tasimacilik" ? styles.typeSelectorActive : styles.typeSelector}
          onClick={() => type !== "tasimacilik" && setType("tasimacilik")}
        >
          {t('vehicles.tasimacilikTxt')}
        </h4>
      </div>
      <Container className={styles.vehiclesContainer}>
        <Row className={styles.vehiclesRow}>
          {filteredCars.map((car, index) => 
            <Card 
              key={index}
              cardIndex={index}
              cardImg={car.cardImg} 
              cardAlt={car.cardAlt} 
              cardName={car.cardText} 
              handleClick={handleClick}
            />
          )}
        </Row>
      </Container>
    </main>
  );
}