import React from 'react';
import styles from './style/Card.module.css';
import Col from "react-bootstrap/Col";

function Card({cardIndex, cardImg, cardAlt, cardName, handleClick}) {
  return (
    <Col data-card={cardIndex} className={styles.cardContainer} onClick={handleClick}>
        <div data-card={cardIndex} className={styles.cardImgContainer}>
            <img data-card={cardIndex} src={cardImg} alt={cardAlt} className={styles.cardImg} />
        </div>
        <div data-card={cardIndex} className={styles.cardTextContainer}>
            <p data-card={cardIndex} className={styles.cardText}>{cardName}</p>    
        </div> 
    </Col>
  );
}

export default Card;