import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './routes/Home';
import Contact from './routes/Contact';
import Services from './routes/Services';
import Vehicles from './routes/Vehicles';
import Atik from './routes/Atik';
import Lojistik from './routes/Lojistik';
import Depolama from './routes/Depolama';
import Peyzaj from './routes/Peyzaj';
import Temizlik from './routes/Temizlik';
import Yenilenebilir from './routes/Yenilenebilir';
import Turizm from './routes/Turizm';
import './i18n';

console.log = console.warn = console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="home" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="services/atik" element={<Atik />} />
          <Route path="services/lojistik" element={<Lojistik />} />
          <Route path="services/depolama" element={<Depolama />} />
          <Route path="services/peyzaj" element={<Peyzaj />} />
          <Route path="services/temizlik" element={<Temizlik />} />
          <Route path="services/yenilenebilir" element={<Yenilenebilir />} />
          <Route path="services/turizm" element={<Turizm />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
