import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/NavbarComponent";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if(location.pathname === "/") {
      navigate("/home");
    }
  }, [navigate, location.pathname]);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
