import React from 'react';
import styles from "./style/Footer.module.css";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={styles.footerContainer}>
        <div className={styles.footerTextContainer}>
          <p className={styles.footerText}>&copy; Dörtyıldız A.Ş., 2022. {t('footer.text')}.</p>
        </div>
    </footer>
  );
}

export default Footer;