import React from "react";
const Landing = React.lazy(() => import('../components/Home/Landing/Landing'));
const About = React.lazy(() => import('../components/Home/About/About'));
const StatementsContainer = React.lazy(() => import('../components/Home/StatementsContainer/StatementsContainer'));
const StatsContainer = React.lazy(() => import('../components/Home/StatsContainer/StatsContainer'));
const ServicesContainer = React.lazy(() => import('../components/Home/ServicesContainer/ServicesContainer'));


export default function Home () {
  
  return (
    <main>
      <Landing />
      <About />
      <StatementsContainer />
      <StatsContainer />
      <ServicesContainer />
    </main>
  );
}