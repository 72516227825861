import React, { useEffect, useState } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "../../images/logo.png";
import styles from "./style/Navbar.module.css";
import { NavLink } from 'react-router-dom';
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import tr from "../../images/tr_flag.png";
import en from "../../images/gb_flag.png";
import { useTranslation } from 'react-i18next';
import ScrollTop from '../ScrollTop/ScrollTop';

function NavbarComponent() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if(language !== "tr" && language !== "en") {
      setLanguage("tr");
    }
  }, [language]);

  return (
    <>
      <style type="text/css">
        {`
          a {
            width: 7rem !important;
          }

          .dropdown-item:hover {
            color: inherit !important;
            background-color: rgb(255,255,255) !important;
          }

          .dropdown-item {
            margin-right: 2rem !important;
          }

          .dropdown-item:focus {
            background-color: rgb(255,255,255) !important;
            border: none !important;
          }

          .dropdown-menu {
            width: 7rem !important;
            min-width: 7rem !important;
          }
          .dropdown-toggle {
            color: #000 !important;
            padding-left: 0 !important;
          }
        `}
      </style>
      <Navbar expand="lg" className={styles.navbar} fixed="top">
        <Container className={styles.navbarContainer}>
            <Navbar.Brand href="/home" className={styles.navbarBrand}>
              <img
                src={logo}
                className={styles.navbarImg}
                alt="Dortyildiz Logo"
              />
            </Navbar.Brand>
            <div className={styles.separator}></div>
            <Navbar.Toggle aria-controls="responsive-navbar" />
            <Navbar.Collapse id="responsive-navbar" className={styles.navbarCollapseContainer}>
              <NavDropdown.Item>
                <NavLink 
                  to="/home" 
                  className={({isActive}) => isActive ? styles.navLinkActive : styles.navLink}
                >
                  {t('navbar.hakkimizda')}
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink 
                  to="/services" 
                  className={({isActive}) => isActive ? styles.navLinkActive : styles.navLink}
                >
                  {t('navbar.hizmetler')}
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink 
                  to="/vehicles" 
                  className={({isActive}) => isActive ? styles.navLinkActive : styles.navLink}
                >
                  {t('navbar.araclar')}
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink 
                  to="/contact" 
                  className={({isActive}) => isActive ? styles.navLinkActive : styles.navLink}
                >
                  {t('navbar.iletisim')}
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavDropdown
                  className={styles.languageSelector}
                  title={language === "tr" ? "TR" : "EN"}
                >
                  <NavDropdown.Item 
                    className={styles.languageItem}
                    onClick={() => changeLanguage("en")}
                  >
                    <img src={en} alt="English" className={styles.flagImg} />
                    EN
                  </NavDropdown.Item>
                  <NavDropdown.Item 
                    className={styles.languageItem}
                    onClick={() => changeLanguage("tr")}
                  >
                    <img src={tr} alt="Türkçe" className={styles.flagImg} />
                    TR
                  </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown.Item>
            </Navbar.Collapse>
        </Container>
        <ScrollTop />
      </Navbar>
  </>
  );
}

export default NavbarComponent;