import caca from "../images/caca.png";
import sikistirmali from "../images/sikistirmali.png";
import yikama from "../images/yikama.png";
import taksi from "../images/taksi.png";
import arazoz from "../images/arazoz.png";
import vidanjor from "../images/vidanjor.png";
import supurge from "../images/supurge.png";
import supurge2 from "../images/supurge2.jpg";
import supurge3 from "../images/supurge3.jpg";
import minisupurge from "../images/minisupurge.png";
import basincliyikama from "../images/basincliyikama.png";
import tuzlama from "../images/tuzlama.png";
import kureme from "../images/kureme.png";
import ismakinesi from "../images/ismakinesi.png";
import kapalikasatir from "../images/kapalikasatir.png";
import kapalikasakamyon from "../images/kapalikasakamyon.png";
import frigotir from "../images/frigotir.png";
import frigokamyon from "../images/frigokamyon.png";
import kamyonet from "../images/kamyonet.png";
import lowbed from "../images/lowbed.png";
import pikap from "../images/pikap.png";
import panelvan from "../images/panelvan.png";
import kayarcop from "../images/kayarcop.png";
import acikcop from "../images/acikcop.png";
import acikcop2 from "../images/acikcop2.jpg";
import sikistirmalicop from "../images/sikistirmalicop.png";
import { useTranslation } from 'react-i18next';

const useVehicle = () => {
    const { t } = useTranslation();
    const VehicleData = [
        {
            id: 0,
            type: "temizlik",
            cardImg: caca,
            cardAlt: "caca kamyon",
            cardText: t('vehicles.caca'),
            cardCarousel: [caca]
        },
        {
            id: 1,
            type: "temizlik",
            cardImg: sikistirmali,
            cardAlt: "Sıkıştırmalı çöp kamyonu",
            cardText: t('vehicles.sikistirmali'),
            cardCarousel: [sikistirmali]
        },
        {
            id: 2,
            type: "temizlik",
            cardImg: yikama,
            cardAlt: "Konteyner yıkama aracı",
            cardText: t('vehicles.konteyner'),
            cardCarousel: [yikama]
        },
        {
            id: 3,
            type: "temizlik",
            cardImg: taksi,
            cardAlt: "Çöp Taksi",
            cardText: t('vehicles.coptaksi'),
            cardCarousel: [taksi]
        },
        {
            id: 4,
            type: "temizlik",
            cardImg: arazoz,
            cardAlt: "Arazöz",
            cardText: t('vehicles.arazoz'),
            cardCarousel: [arazoz]
        },
        {
            id: 5,
            type: "temizlik",
            cardImg: vidanjor,
            cardAlt: "Vidanjör",
            cardText: t('vehicles.vidanjor'),
            cardCarousel: [vidanjor]
        },
        {
            id: 6,
            type: "temizlik",
            cardImg: supurge,
            cardAlt: "Vakumlu yol süpürgesi",
            cardText: t('vehicles.vakumlusupurge'),
            cardCarousel: [supurge, supurge2, supurge3]
        },
        {
            id: 7,
            type: "temizlik",
            cardImg: minisupurge,
            cardAlt: "Mini süpürge",
            cardText: t('vehicles.minisupurge'),
            cardCarousel: [minisupurge]
        },
        {
            id: 8,
            type: "temizlik",
            cardImg: basincliyikama,
            cardAlt: "Basınçlı yıkama ünitesi mobil araç",
            cardText: t('vehicles.basincliunite'),
            cardCarousel: [basincliyikama]
        },
        {
            id: 9,
            type: "temizlik",
            cardImg: tuzlama,
            cardAlt: "Yol tuzlama aracı",
            cardText: t('vehicles.yoltuzlama'),
            cardCarousel: [tuzlama]
        },
        {
            id: 10,
            type: "temizlik",
            cardImg: kureme,
            cardAlt: "Kar küreme ve yükleme ekipmanlı traktör",
            cardText: t('vehicles.karkureme'),
            cardCarousel: [kureme]
        },
        {
            id: 11,
            type: "temizlik",
            cardImg: ismakinesi,
            cardAlt: "İş makinaları",
            cardText: t('vehicles.ismakina'),
            cardCarousel: [ismakinesi]
        },
        {
            id: 12,
            type: "tasimacilik",
            cardImg: kapalikasatir,
            cardAlt: "Kapalı çelik kasalı tır",
            cardText: t('vehicles.kapalikasatir'),
            cardCarousel: [kapalikasatir]
        },
        {
            id: 13,
            type: "tasimacilik",
            cardImg: kapalikasakamyon,
            cardAlt: "Kapalı çelik kasalı kamyon",
            cardText: t('vehicles.kapalikasakamyon'),
            cardCarousel: [kapalikasakamyon]
        },
        {
            id: 14,
            type: "tasimacilik",
            cardImg: frigotir,
            cardAlt: "Soğutuculu tır (Frigo)",
            cardText: t('vehicles.sogutucutir'),
            cardCarousel: [frigotir]
        },
        {
            id: 15,
            type: "tasimacilik",
            cardImg: frigokamyon,
            cardAlt: "Soğutuculu kamyon (Frigo)",
            cardText: t('vehicles.sogutucukamyon'),
            cardCarousel: [frigokamyon]
        },
        {
            id: 16,
            type: "tasimacilik",
            cardImg: kamyonet,
            cardAlt: "Kamyonet",
            cardText: t('vehicles.kamyonet'),
            cardCarousel: [kamyonet]
        },
        {
            id: 17,
            type: "tasimacilik",
            cardImg: lowbed,
            cardAlt: "Low-bed",
            cardText: t('vehicles.lowbed'),
            cardCarousel: [lowbed]
        },
        {
            id: 18,
            type: "tasimacilik",
            cardImg: pikap,
            cardAlt: "Pikap",
            cardText: t('vehicles.pikap'),
            cardCarousel: [pikap]
        },
        {
            id: 19,
            type: "tasimacilik",
            cardImg: panelvan,
            cardAlt: "Panelvan",
            cardText: t('vehicles.panelvan'),
            cardCarousel: [panelvan]
        },
        {
            id: 20,
            type: "transfer",
            cardImg: sikistirmalicop,
            cardAlt: "Sıkıştırmalı çöp transfer semi-treyleri",
            cardText: t('vehicles.sikistirmali'),
            cardCarousel: [sikistirmalicop]
        },
        {
            id: 21,
            type: "transfer",
            cardImg: kayarcop,
            cardAlt: "Kayar tabanlı çöp transfer semi-treyleri",
            cardText: t('vehicles.kayartabanli'),
            cardCarousel: [kayarcop]
        },
        {
            id: 22,
            type: "transfer",
            cardImg: acikcop,
            cardAlt: "Açık kasa üstten tenteli damperli çöp taşıma kamyonu",
            cardText: t('vehicles.acikkasa'),
            cardCarousel: [acikcop, acikcop2]
        }
    ];
    return VehicleData;
}

export default useVehicle;